@import '../../../../variables';

.StarsMainView {
  background: $white;
  position: relative;

  &>.SlickSliderContainer .SlidePlaceholder {
    height: 40vw;
  }

  & > .SlickSliderContainer .SlidePlaceholder {
    height: 40vw;
  }

  .StarsMainView-inner {
    padding: 20px 20px 0;
  }

  .Met-Our-Stars-Section {
    margin: 20px 0;

    @media (min-width: $breakpoint-tablet) {
      text-align: center;
    }

    .Met-Our-Stars-Text {
      word-spacing: -1px;
      font-family: $font-family-metropolis;

      .Met-Our-Stars-Title {
        text-transform: uppercase;
        color: $primary;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .Description {
        font-weight: 500;
        opacity: 0.8;
      }
    }
  }

  .Images-Table,
  .ExclusiveStars {
    display: grid;
    gap: 2px;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .ExclusiveStars {
    .ExclusiveStar {
      text-align: center;

      img {
        height: 100%;
      }

      h2 {
        margin: 5px 0 0;
        font-size: inherit;
        font-weight: 300;
        color: $teal;
        text-transform: uppercase;
      }
    }
  }

  .StarImagePlaceholder {
    height: 280px;
    margin-bottom: 30px;
  }

  .StarsView-Sections {
    .OurStars {
      font-size: 30px;
      margin-bottom: 30px;
      font-weight: 400;
      color: $primary;
      text-transform: uppercase;
      font-family: $font-family-metropolis;
    }

    .ShowMeSortByNavigation {
      display: none;
      margin: 10px 0;
      border-radius: 5px;

      .Section {
        &:first-of-type {
          flex: 1;
          min-width: 0;
          margin-right: 10px;
        }
      }
    }

    .Image-Container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ImageComponent {
        height: 100%;
        width: 100%;

        img {
          object-fit: cover;
          height: 100%;
        }
      }

      .Image-Text {
        .Text {
          text-transform: uppercase;
          color: $teal;
          font-weight: 300;
        }
      }
    }

    .PageNavigationContainer {
      margin: 20px 0;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .StarsView-Sections {
      .StarSpotlight {
        width: 100%;
        height: 370px;

        .Details {
          min-width: $breakpoint-mobile;
        }
      }

      .ShowMeSortByNavigation {
        display: flex;
      }

      .StarImagePlaceholder {
        height: 450px;
        margin-bottom: 30px;
      }

      .PageNavigationContainer {
        margin: 40px 0px;
        justify-content: center;

        .PageIndicator {
          .Previous {
            margin-right: 10px;
          }

          .Separator {
            color: $gray;
          }
        }
      }
    }
  }
}